import React from 'react';
import styled from 'styled-components';
import { graphql } from 'gatsby';
import SEO from '../../seo';
import Layout from '../../layout';
import ItemBody from './ItemBody';
import MoreItems  from './MoreItems';
import PopularPosts from '../../popular';
import Content_Sidebar_Wrap from '../../general/Content_Sidebar_Wrap';
import Footer from '../../footer';

class SingleItemTemplate extends React.Component {
  render() {
    const {data, pageContext, location} = this.props;
    const { node } = data.allMdx.edges[0];
    const { body, frontmatter } = node;
    const { date: articleDate, metaDesc, tag, title } = frontmatter;

    const useDate = () => {
      const event = new Date(articleDate);
      const options = { year: 'numeric', month: 'numeric', day: 'numeric' };
      return event.toLocaleDateString('ru-RU', options)
    }
    return (
      <Layout>
        <SEO
          customTitle={`${title}`}
          customDescription={`${metaDesc}`}
          canonical={location.pathname}
        />
        <Inner>
          <Content_Sidebar_Wrap>
            <Article>
              <header>
                <h1>{title}</h1>
                {/* <p>{objective}</p> */}
                <Meta>
                  <DateContainer>
                    <span>Дата: <time dateTime={articleDate} itemProp="datePublished">{useDate()}</time></span>
                  </DateContainer>
                  <Tag>
                    {/* <Link to={`/`}>{tag}</Link> */}
                    <span>{tag}</span>
                  </Tag>
                </Meta>
              </header>
              <ItemBody body={body} />
              <MoreItems pageContext={pageContext}/>
            </Article>
          </Content_Sidebar_Wrap>

          <PopularPosts />
        </Inner>

        <Footer />
      </Layout>
    );
  }
}

const Inner = styled.div`
  display: grid;
  grid-template-columns: minmax(524px, 930px) minmax(120px, 320px);
  padding: 0px 70px 0px 40px;

  @media only screen and (max-width: 1200px) {
    padding: 0px 52px 0px 28px;
  }
  @media only screen and (max-width: 1000px) {
    display: block;
    padding: 0px 22px 0px 22px;
  }
`;

const Meta = styled.div`
  display: flex;
  font-size: 14px;
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  font-style: italic;
`;

const DateContainer = styled.div`
  color: #000;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
  line-height: 1.8;
  &:after {
    content: '×';
    margin-left: 5px;
    color: ${(props) => props.theme.red};
  }
`;

const Tag = styled.div`
  margin-bottom: 5px;
  span {
    color: #000;
  }
`;

const Article = styled.article`

  h1 {
    margin-bottom: 32px;
  }
  a {
    border-bottom: ${(props) => `2px solid ${props.theme.red}`};
  }
  ul {
    margin: 0 0 20px 0;
    padding: 0;

    li {
      list-style: none;
      margin-left: 40px;
      /* list-style-type: square; */
    }
    li::before {
      content: '■';
      margin-right: 0.5rem;
      color: ${(props) => props.theme.red};
    }
  }
  ol {
    margin: 0 0 20px 0;
    padding: 0;
    li {
      margin-left: 40px;
    }
  }
  h2 {
    margin: 28px 0;
  }
  h3 {
    margin: 28px 0;
  }
  p {
    margin: 0 0 20px 0;
  }
  header {
    border-bottom: 1px solid #333;
    margin: 0 0 50px;
    padding: 0 0 0;
  }
  code {
    padding: 2px 4px;
    font-size: 100%;
    color: #444;
    background-color: #f1f0ea;
    border-radius: 4px;
  }
  .h2 {
    font-size: 2.5rem;
    margin: 28px 0;
    text-align: left;
    line-height: 1.2;
    font-family: 'Roboto', sans-serif;
    font-weight: 700;
    font-style: italic;
    @media (max-width: 800px) {
      font-size: 2rem;
    }
  }
  .highlight {
    margin: 28px 0;
    display: block;
    overflow-x: auto;
    padding: 5px 5px 5px 20px;
    font-size: 100%;
    color: #000;
    background-color: #f1f1f1;
    border-left: ${(props) => `2px solid ${props.theme.red}`};
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.16),
      0 2px 10px 0 rgba(0, 0, 0, 0.12) !important;
  }
  .videoEmbed {
    overflow: hidden;
    /* 16:9 aspect ratio */
    padding-top: 56.25%;
    position: relative;
    margin: 0 0 20px 0;
    iframe {
      border: 0;
      height: 100%;
      left: 0;
      position: absolute;
      top: 0;
      width: 100%;
    }
  }

  @media only screen and (max-width: 1200px) {
    h1 {
      font-size: 26px;
      margin-bottom: 22px;
    }
    header {
      margin-bottom: 38px;
    }
    p {
      font-size: 16px;
    }
    .highlight {
      font-size: 16px;
      margin: 22px 0;
    }
    h2 { 
      font-size: 22px;
      margin: 22px 0;
    }
    ul, ol {
      font-size: 16px;
    }
    ul li {
      margin-left: 18px;
    }
    ol li {
      margin-left: 18px;
    }
  }
`;

export default SingleItemTemplate;

export const pageQuery = graphql`
  query BlogPostBySlug($slug: String!) {
    allMdx(filter: { frontmatter: { slug: { eq: $slug } } }) {
      edges {
        node {
          excerpt(pruneLength: 160)
          body
          frontmatter {
            title
            objective
            metaDesc
            slug
            tag
            date
          }
        }
      }
    }
  }
`;
