import React from 'react';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import styled from 'styled-components';

class ItemBody extends React.Component {
  render() {
    const { body } = this.props;

    return (
      <Container>
        <div>
        <MDXRenderer>{body}</MDXRenderer>  
        </div>

      </Container>
    );
  }
}

const Container = styled.div`
 img {
    max-width: 100%;
    object-fit: contain;
    box-shadow: 0 0 0 1px #d4d4d4;
    border: 2px solid #fff;
  }
`;


export default ItemBody;
