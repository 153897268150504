import React from 'react';
import styled from 'styled-components';
import { Link } from 'gatsby';

const MoreItems = (props) => {
    const { next, previous } = props.pageContext;

    return (<Container next={next} previous={previous}>
        {next && <Link to={`/blog/${next.frontmatter.slug}`}>
            <strong>← Назад</strong>
            <p className="article-title">{next.frontmatter.title}</p>
            </Link>}
        {previous && <Link to={`/blog/${previous.frontmatter.slug}`}>
            <strong>Вперед →</strong>
            <p className="article-title">{previous.frontmatter.title}</p>
        </Link>}
    </Container>);
}

export default MoreItems;

const Container = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: auto auto;
    border: ${(props) => `1.5px solid ${props.theme.black}`};
    border-radius: 1rem;
    margin: 4rem 0px;
    a {
        display: grid;
        grid-template-rows: auto 1fr;
        text-decoration: none;
        border-bottom: none !important;
        grid-column: ${props => !props.next || !props.previous ? '1/-1' : '0'};
        &:nth-child(2) {
            text-align: right;
            border-left: ${(props) => `1.5px solid ${props.theme.black}`};
        }
        strong {
            font-family: 'Roboto Condensed',sans-serif;
            font-weight: 700;
            padding: 1rem 2rem;
            border-bottom: ${(props) => `1.5px solid ${props.theme.black}`};
            text-decoration-line: underline;
            text-decoration-style: solid;
            text-decoration-thickness: auto;
            text-decoration-color: ${props => props.theme.red};
            ;
        }
        p {
            font-family: 'Roboto',sans-serif;
            font-weight: 400;
            font-size: 16px;
            padding: 1rem 2rem;
            margin: 0;
        }
    }

    @media only screen and (max-width: 760px) {
        .article-title {
            display: none;
        }
    }
`;



